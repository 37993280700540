import './polyfills'
import React from 'react'
import 'react-app-polyfill/ie11'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import store from './store'
import App from './containers/App'
import EulaNotice from './EulaNotice'
import './index.scss'
import { createRoot } from 'react-dom/client'
import {init as initFullStory} from "@fullstory/browser";
import { portalEnv } from '../src/utils/environmentHelpers'

const currentEnv = portalEnv(window.location.host)
if (currentEnv === "production") {
    initFullStory({orgId: 'o-1ZQKTS-na1'})
}

function VendorPortal() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path='/eula' children={<EulaNotice />} />
          <Route path='/' children={<App />} />
        </Switch>
      </BrowserRouter>
    </Provider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<VendorPortal />)
