import {
  FETCHING_USER,
  FETCH_USER_FAILURE,
  RESET_PASSWORD_SUCCESS,
  LOGGED_IN,
  SET_USER_INFO,
  SET_CURRENT_EULA_ACCEPTED,
  RESET_LOGIN,
} from '../actions/Types'

const initialState = {
  data: {},
  loggedIn: false,
  fetching: false,
  resetPasswordSuccess: false,
  userInfo: {
    dismissed_vendor_portal_intro: true,
    customer_id: '',
    customer_name: ''
  },
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, userInfo: { ...action.userInfo } }
    case SET_CURRENT_EULA_ACCEPTED:
      return {
        ...state,
        userInfo: { ...state.userInfo, has_signed_current_eula: true },
      }
    case FETCHING_USER:
      return { ...state, fetching: action.payload }
    case FETCH_USER_FAILURE:
      return {
        ...state,
        fetching: action.isFetching,
        loginErrorMessage: action.errorMessage,
      }
    case LOGGED_IN:
      return {
        ...state,
        data: action.user,
        loggedIn: true,
        loginErrorMessage: '',
      }
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: action.payload }
    case RESET_LOGIN:
      return initialState
    default:
      return state
  }
}

export default user
