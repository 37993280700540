import React from 'react'
// import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthContainer from '../AuthContainer'
import Dashboard from '../../components/dashboard/Dashboard'
import TwoStepVerification from '../../components/twoStepVerification/TwoStepVerification'

// Import Dashboard component

const AuthRoutes = () => {

  return (
    <AuthContainer>
      <>
        <Switch key='auth-switch'>
          <Route exact path='/dashboard' children={<Dashboard />} />
          <Route
            path='/multi-factor-verification'
            children={<TwoStepVerification />}
          />
          <Route render={() => <Redirect to='/dashboard' />} />
        </Switch>
      </>
    </AuthContainer>
  )
}

export default AuthRoutes
